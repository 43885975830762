export const mobileLoginPage = () => {
  const loginPage = document.querySelector('.customLoginPage');

  if (!loginPage || !loginPage.classList.contains('login-mobile')) return;

  const loginToggle = document.querySelector('.customLoginPage__login-mobile-toggle');
  const registrationToggle = document.querySelector('.customLoginPage__registration-mobile-toggle');
  const loginBox = document.querySelector('.customLoginPage__login');
  const registrationBox = document.querySelector('.customLoginPage__registration');
  const noticesWrapper = document.querySelector('.woocommerce-notices-wrapper');

  if (loginToggle && loginBox) {
    loginToggle.addEventListener('click', () => {
      loginToggle.classList.toggle('active');
      loginBox.classList.toggle('active');
    });
  }

  if (registrationToggle && registrationBox) {
    registrationToggle.addEventListener('click', () => {
      registrationToggle.classList.toggle('active');
      registrationBox.classList.toggle('active');
    });
  }

  // Check on load if error message exists in .woocommerce-notices-wrapper
  if (noticesWrapper && noticesWrapper.innerText.includes('Nieprawidłowe dane logowania')) {
    loginToggle?.click(); // Simulate click to open login box
  }
};

export const weightCalculator = () => {
  document.addEventListener( 'DOMContentLoaded', function () {
    const weight = document.querySelector(".weightCalculator").getAttribute('data-weight');
    const calcInput = document.querySelector(".weightCalculator #weight");
    const calcResult = document.querySelector(".weightCalculator .result");

    calcInput.addEventListener('input', event => {
      if(event.target.value != '') {

        const calcInputValue = event.target.value.replace(/,/g, '.');

        if( !isNaN(calcInputValue) ) {
          if(calcInputValue > 0) {
            const result = Math.ceil(calcInputValue/weight) * 10;

            calcResult.innerHTML = `Potrzebujesz <b>${result} g</b> nasion`;
          }
         
        } else {
          calcResult.textContent = `podaj wartość liczbową!`;
        }
        
      } else {
        calcResult.textContent = '';
      }
    })

  })
}